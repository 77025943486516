import styled from "styled-components";
import Flex from "../FlexBox/Flex";

// This is _only_ for sizing/layout. It does not include rounded corners or anything.
// Adding rounded corners makes this a RoundedBox, otherwise it's just the correct _size_
// but can be used on the full-page white background
const BoxContent = styled(Flex)`
  max-width: 400px;
  margin-top: 32px;
  padding: 16px 0;

  @media (max-width: 440px) {
    width: 100%;
    min-width: 320px;
    margin-top: 0;
    padding: 0;
  }
`;

export default BoxContent;
