import React from "react";
import { useRouter } from "next/router";
import withTranslation from "next-translate/withTranslation";
import {
  Flex,
  Heading,
  CloverleafBackgroundSpan,
  Text,
  Button,
  Box
} from "@/components/ui";
import Layout from "@/components/ui/Layout";
import BoxContent from "@/components/ui/styled/BoxContent";
import { useLocale } from "@/lib/hooks";
import { serverSideTranslations } from "@/lib/hooks/useLocale";

function ErrorPage() {
  const { t } = useLocale();

  const router = useRouter();

  return (
    <Layout background="white">
      <BoxContent align="center" justify="center" style={{ maxWidth: "600px" }} column>
        <Flex w={1} my={3} p={1} align="center" column>
          <Heading uppercase color="black" fontSize="3em" noMargin style={{ lineHeight: "1.4em" }}>
            <CloverleafBackgroundSpan>
              {t("uh-oh")}
            </CloverleafBackgroundSpan>
            {t("looking-for")}
          </Heading>
          <Box w={1}>
            <Text center extraLarge>
              {t("lost")}
            </Text>
          </Box>
          <Flex w={1} justify="center" wrap>
            <Box m={2}>
              <Button primary onClick={router.back}>
                {t("go-back")}
              </Button>
            </Box>
            <Box m={2}>
              <Button primary onClick={() => router.push("/my-dashboard")}>
                {t("home")}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </BoxContent>
    </Layout>
  );
}

export async function getStaticProps(ctx) {
  const translations = await serverSideTranslations(ctx.locale, [
    "common",
  ]);

  return {
    props: {
      ...translations,
    },
  };
}

const TranslatedErrorPage = withTranslation(ErrorPage, "common");

const Page404 = (props) => {
  return <TranslatedErrorPage {...props} useSuspense={false} />;
};

export default Page404;
